import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeft, Calendar, Clock, Award, Users, Check, X } from 'lucide-react';
import axios from 'axios';
const SingleCourse = () => {
  const [showEnrollForm, setShowEnrollForm] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    contactNo: '',
    emailAddress: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const data = [
    {
      id: 1,
      title: "HR Generalist",
      type: "HR & Talent Management",
      duration: "8 weeks",
      rating: 4.5,
      description: "Comprehensive training for HR generalists covering all key HR functions.",
      highlights: [
        "Complete overview of HR functions and responsibilities",
        "Recruitment and onboarding best practices",
        "Performance management systems",
        "Employee relations and conflict resolution",
        "HR documentation and compliance"
      ],
      students: 245,
      lastUpdated: "January 2025"
    },
    {
      id: 2,
      title: "HR Analytics",
      type: "HR & Talent Management",
      duration: "6 weeks",
      rating: 4.7,
      description: "Learn to analyze HR data to make data-driven decisions.",
      highlights: [
        "HR data analysis tools and techniques",
        "Key HR metrics and KPIs",
        "Data visualization for HR",
        "Predictive analytics in workforce planning",
        "Real-world HR analytics case studies"
      ],
      students: 312,
      lastUpdated: "March 2024"
    },
    {
      id: 3,
      title: "HR Policies Workshop",
      type: "HR & Talent Management",
      duration: "2 days",
      rating: 4.6,
      description: "Workshop focused on creating and implementing effective HR policies.",
      highlights: [
        "Policy design and implementation strategies",
        "Legal compliance frameworks",
        "Employee handbook development",
        "Conflict resolution policies",
        "Workshop-based learning with templates"
      ],
      students: 178,
      lastUpdated: "November 2024"
    },
    {
      id: 4,
      title: "HR Metrics",
      type: "HR & Talent Management",
      duration: "4 weeks",
      rating: 4.4,
      description: "Understand and apply key HR metrics to measure performance.",
      highlights: [
        "Essential HR metrics and calculations",
        "Workforce analytics fundamentals",
        "Turnover and retention analysis",
        "Productivity measurement techniques",
        "Data-driven decision making"
      ],
      students: 195,
      lastUpdated: "February 2024"
    },
    {
      id: 5,
      title: "Talent Acquisition",
      type: "HR & Talent Management",
      duration: "6 weeks",
      rating: 4.8,
      description: "Strategies and techniques for effective talent acquisition.",
      highlights: [
        "Sourcing strategies for hard-to-fill roles",
        "Candidate assessment techniques",
        "Employer branding essentials",
        "AI in recruitment",
        "Offer negotiation strategies"
      ],
      students: 428,
      lastUpdated: "September 2024"
    },
    {
      id: 6,
      title: "Succession Planning Workshop",
      type: "HR & Talent Management",
      duration: "2 days",
      rating: 4.5,
      description: "Learn to develop and implement succession planning strategies.",
      highlights: [
        "Leadership pipeline development",
        "Competency mapping exercises",
        "Risk assessment in succession planning",
        "Knowledge transfer strategies",
        "Interactive scenario planning"
      ],
      students: 162,
      lastUpdated: "July 2024"
    },
    {
      id: 7,
      title: "Organizational Development",
      type: "HR & Talent Management",
      duration: "8 weeks",
      rating: 4.7,
      description: "Training on organizational development strategies and practices.",
      highlights: [
        "Change management frameworks",
        "Cultural transformation strategies",
        "Team effectiveness diagnostics",
        "Organizational design principles",
        "Case studies from Fortune 500 companies"
      ],
      students: 287,
      lastUpdated: "April 2024"
    },
    {
      id: 8,
      title: "Payroll Workshop",
      type: "Payroll & Compliance",
      duration: "3 days",
      rating: 4.6,
      description: "Hands-on workshop for managing payroll processes.",
      highlights: [
        "End-to-end payroll processing",
        "Tax compliance updates",
        "Payroll software simulations",
        "Error detection and correction",
        "Best practices in payroll auditing"
      ],
      students: 324,
      lastUpdated: "October 2024"
    },
    {
      id: 9,
      title: "Payslip Generation Workshop",
      type: "Payroll & Compliance",
      duration: "2 days",
      rating: 4.5,
      description: "Learn to generate and manage payslips effectively.",
      highlights: [
        "Automated payslip generation",
        "Statutory component breakdown",
        "Digital payslip distribution",
        "Error resolution techniques",
        "Compliance with latest regulations"
      ],
      students: 215,
      lastUpdated: "June 2024"
    },
    {
      id: 10,
      title: "Power BI for HR",
      type: "Payroll & Compliance",
      duration: "4 weeks",
      rating: 4.8,
      description: "Use Power BI to visualize and analyze HR data.",
      highlights: [
        "HR dashboard creation",
        "Data cleaning techniques",
        "Advanced visualization methods",
        "Predictive analytics integration",
        "Real-time reporting solutions"
      ],
      students: 512,
      lastUpdated: "August 2024"
    },
    {
      id: 11,
      title: "Statutory Compliances",
      type: "Payroll & Compliance",
      duration: "6 weeks",
      rating: 4.7,
      description: "Understand and implement statutory compliance requirements.",
      highlights: [
        "Latest regulatory updates",
        "Compliance audit preparation",
        "Risk mitigation strategies",
        "Documentation best practices",
        "Cross-border compliance issues"
      ],
      students: 278,
      lastUpdated: "December 2024"
    },
    {
      id: 12,
      title: "Labour Laws",
      type: "Payroll & Compliance",
      duration: "4 weeks",
      rating: 4.6,
      description: "Comprehensive training on labor laws and regulations.",
      highlights: [
        "Key labor legislation breakdown",
        "Employee rights and obligations",
        "Dispute resolution mechanisms",
        "Collective bargaining agreements",
        "Recent amendments analysis"
      ],
      students: 305,
      lastUpdated: "May 2024"
    },
    {
      id: 13,
      title: "Leave Policies",
      type: "Payroll & Compliance",
      duration: "2 weeks",
      rating: 4.5,
      description: "Learn to design and implement effective leave policies.",
      highlights: [
        "Global leave policy benchmarks",
        "Statutory leave requirements",
        "Leave tracking systems",
        "Policy customization strategies",
        "COVID-era policy adaptations"
      ],
      students: 189,
      lastUpdated: "March 2024"
    },
    {
      id: 14,
      title: "Interview Skills",
      type: "Interview & Career Development",
      duration: "4 weeks",
      rating: 4.7,
      description: "Develop and refine your interview skills for better performance.",
      highlights: [
        "Behavioral interview techniques",
        "STAR method mastery",
        "Virtual interview best practices",
        "Salary negotiation strategies",
        "Panel interview simulations"
      ],
      students: 432,
      lastUpdated: "November 2024"
    },
    {
      id: 15,
      title: "Mock Interview Workshops",
      type: "Interview & Career Development",
      duration: "2 days",
      rating: 4.6,
      description: "Practice and improve your interview techniques through mock interviews.",
      highlights: [
        "Industry-specific mock interviews",
        "Real-time feedback from HR experts",
        "Body language analysis",
        "Stress interview simulations",
        "Post-interview evaluation framework"
      ],
      students: 326,
      lastUpdated: "February 2024"
    },
    {
      id: 16,
      title: "Spoken English",
      type: "Interview & Career Development",
      duration: "8 weeks",
      rating: 4.8,
      description: "Improve your spoken English for professional communication.",
      highlights: [
        "Accent neutralization techniques",
        "Business vocabulary building",
        "Presentation skills development",
        "Telephonic communication mastery",
        "Cross-cultural communication strategies"
      ],
      students: 689,
      lastUpdated: "January 2025"
    },
    {
      id: 17,
      title: "Business Communication",
      type: "Interview & Career Development",
      duration: "6 weeks",
      rating: 4.7,
      description: "Enhance your business communication skills for effective workplace interactions.",
      highlights: [
        "Professional email writing",
        "Meeting management techniques",
        "Executive summary creation",
        "Non-verbal communication mastery",
        "Crisis communication strategies"
      ],
      students: 415,
      lastUpdated: "July 2024"
    },
    {
      id: 18,
      title: "Personality Development",
      type: "Interview & Career Development",
      duration: "8 weeks",
      rating: 4.6,
      description: "Develop your personality and soft skills for career growth.",
      highlights: [
        "Emotional intelligence training",
        "Leadership presence development",
        "Conflict resolution workshops",
        "Time management frameworks",
        "Personal branding strategies"
      ],
      students: 378,
      lastUpdated: "April 2024"
    },
    {
      id: 19,
      title: "Advanced Excel",
      type: "Corporate & Skill-Based Training",
      duration: "6 weeks",
      rating: 4.8,
      description: "Master advanced Excel techniques for data analysis and reporting.",
      highlights: [
        "Power Query automation",
        "Advanced pivot table techniques",
        "Macros and VBA scripting",
        "Dashboard creation mastery",
        "Data validation best practices"
      ],
      students: 892,
      lastUpdated: "October 2024"
    },
    {
      id: 20,
      title: "Skill Matrix Workshop",
      type: "Corporate & Skill-Based Training",
      duration: "2 days",
      rating: 4.5,
      description: "Learn to create and manage skill matrices for workforce planning.",
      highlights: [
        "Competency mapping techniques",
        "Gap analysis methodologies",
        "Succession planning integration",
        "Software tools demonstration",
        "Real-time team skill visualization"
      ],
      students: 204,
      lastUpdated: "June 2024"
    },
    {
      id: 21,
      title: "Corporate Training",
      type: "Corporate & Skill-Based Training",
      duration: "12 weeks",
      rating: 4.7,
      description: "Comprehensive corporate training program for skill development.",
      highlights: [
        "Needs assessment frameworks",
        "Training ROI measurement",
        "Blended learning strategies",
        "Leadership development modules",
        "Customizable program architecture"
      ],
      students: 356,
      lastUpdated: "September 2024"
    },
    {
      id: 22,
      title: "Outbound Training",
      type: "Corporate & Skill-Based Training",
      duration: "3 days",
      rating: 4.6,
      description: "Outdoor training activities for team building and leadership development.",
      highlights: [
        "Adventure-based learning",
        "Leadership challenge simulations",
        "Team dynamics analysis",
        "Debriefing and reflection sessions",
        "Customizable outdoor modules"
      ],
      students: 267,
      lastUpdated: "May 2024"
    },
    {
      id: 23,
      title: "Employee Retention Workshop",
      type: "Employee Engagement & Retention",
      duration: "2 days",
      rating: 4.7,
      description: "Strategies and techniques to improve employee retention.",
      highlights: [
        "Retention analytics",
        "Stay interview techniques",
        "Recognition program design",
        "Career pathing strategies",
        "Exit interview analysis"
      ],
      students: 198,
      lastUpdated: "August 2024"
    },
    {
      id: 24,
      title: "Internship",
      type: "Internship & Practical Exposure",
      duration: "12 weeks",
      rating: 4.8,
      description: "Gain practical exposure and hands-on experience through an internship.",
      highlights: [
        "Real-project assignments",
        "Mentorship program",
        "Industry networking opportunities",
        "Skill-based rotations",
        "Final evaluation and certification"
      ],
      students: 745,
      lastUpdated: "December 2024"
    }
  ];

  const extendedData = data.map(course => {
    if (course.id > 3) {
      return {
        ...course,
        // highlights: [
        //   "Key concept 1 for this course",
        //   "Important skill development opportunity",
        //   "Practical application techniques",
        //   "Industry best practices",
        //   "Career advancement strategies"
        // ],
        students: Math.floor(Math.random() * 300) + 100,
        lastUpdated: "February 2025"
      };
    }
    return course;
  });

  const { id } = useParams(); // Get the course ID from the URL
  const course = extendedData.find((course) => course.id === parseInt(id));

  if (!course) {
    return (
      <div className="bg-gray-50 min-h-screen p-4 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
          <div className="text-red-600 text-6xl mb-4">404</div>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Course Not Found</h2>
          <p className="text-gray-600 mb-6">The course you are looking for doesn't exist or has been removed.</p>
          <a 
            href="/trainingDevelopment" 
            className="inline-flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-300"
          >
            <ArrowLeft size={16} className="mr-2" />
            Back to All Courses
          </a>
        </div>
      </div>
    );
  }

  // Function to render star ratings
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const stars = [];
    
    for (let i = 0; i < fullStars; i++) {
      stars.push(<span key={`full-${i}`} className="text-yellow-400">★</span>);
    }
    
    if (halfStar) {
      stars.push(<span key="half" className="text-yellow-400">★</span>);
    }
    
    const emptyStars = 5 - stars.length;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<span key={`empty-${i}`} className="text-gray-300">★</span>);
    }
    
    return <div className="flex">{stars}</div>;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.fullName.trim()) {
      errors.fullName = "Full name is required";
    }
    
    if (!formData.contactNo.trim()) {
      errors.contactNo = "Contact number is required";
    } else if (!/^\d{10,15}$/.test(formData.contactNo.replace(/[-()\s]/g, ''))) {
      errors.contactNo = "Please enter a valid contact number";
    }
    
    if (!formData.emailAddress.trim()) {
      errors.emailAddress = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      errors.emailAddress = "Please enter a valid email address";
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      setIsSubmitting(true);
  
      try {
        // Make an API call using Axios
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/enroll`, {
          courseId: course.id,
          courseTitle: course.title, 
          ...formData
        });
  
        if (response.status === 201) {
          setSubmitSuccess(true);
        } else {
          setSubmitError(true);
        }
      } catch (error) {
        console.error("Enrollment failed:", error);
        setSubmitError(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      fullName: '',
      contactNo: '',
      emailAddress: ''
    });
    setFormErrors({});
    setSubmitSuccess(false);
    setSubmitError(false);
    setShowEnrollForm(false);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Course Header */}
      <div className="bg-gradient-to-r from-red-700 to-red-500 text-white py-16">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex items-center gap-2 mb-6">
            <a 
              href="/trainingDevelopment" 
              className="inline-flex items-center text-white/90 hover:text-white transition-colors text-sm font-medium"
            >
              <ArrowLeft size={16} className="mr-1" />
              Back to Courses
            </a>
            <span className="text-white/50 mx-2">|</span>
            <span className="text-white/90 text-sm">{course.type}</span>
          </div>
          <h1 className="text-3xl md:text-5xl font-bold mb-4">{course.title}</h1>
          <p className="text-xl text-white/90 max-w-3xl mb-6">{course.description}</p>
          <div className="flex flex-wrap items-center gap-3 md:gap-6">
            <div className="flex items-center">
              <div className="mr-2">{renderStars(course.rating)}</div>
              <span>({course.rating})</span>
            </div>
            <div className="flex items-center">
              <Calendar size={18} className="mr-2" />
              <span>Last updated: {course.lastUpdated}</span>
            </div>
            <div className="flex items-center">
              <Users size={18} className="mr-2" />
              <span>{course.students} students enrolled</span>
            </div>
          </div>
        </div>
      </div>

      {/* Course Content */}
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="bg-white rounded-xl shadow-md p-6 mb-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Course Overview</h2>
              <p className="text-gray-700 mb-6">{course.description}</p>
              
              <h3 className="text-xl font-bold text-gray-800 mb-3">What You'll Learn</h3>
              <ul className="mb-6">
                {course.highlights.map((highlight, index) => (
                  <li key={index} className="flex items-start mb-3">
                    <span className="mr-2 mt-1 text-green-500 flex-shrink-0">
                      <Check size={18} />
                    </span>
                    <span className="text-gray-700">{highlight}</span>
                  </li>
                ))}
              </ul>
              
              <h3 className="text-xl font-bold text-gray-800 mb-3">Course Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="flex items-center">
                  <Clock size={20} className="mr-3 text-red-600" />
                  <div>
                    <p className="text-sm text-gray-500">Duration</p>
                    <p className="font-medium">{course.duration}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Award size={20} className="mr-3 text-red-600" />
                  <div>
                    <p className="text-sm text-gray-500">Certification</p>
                    <p className="font-medium">Certificate of Completion</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Enrollment Card */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-xl shadow-md p-6 sticky top-4">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <Clock size={20} className="mr-2 text-red-600" />
                  <span className="font-medium">{course.duration}</span>
                </div>
                <div className="bg-red-100 text-red-700 px-3 py-1 rounded-full text-sm font-medium">
                  {course.type.split(' ')[0]}
                </div>
              </div>
              
              {!showEnrollForm && !submitSuccess && (
                <button
                  onClick={() => setShowEnrollForm(true)}
                  className="w-full bg-red-600 hover:bg-red-700 text-white font-medium py-3 px-4 rounded-lg transition-colors duration-300 mb-4 flex items-center justify-center"
                >
                  Enroll in This Course
                </button>
              )}
              
              {/* Enrollment Form */}
              {showEnrollForm && !submitSuccess && (
                <form onSubmit={handleSubmit} className="mt-4">
                  <h3 className="text-lg font-bold text-gray-800 mb-4">Complete Your Enrollment</h3>
                  
                  <div className="mb-4">
                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border ${formErrors.fullName ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-red-500`}
                      placeholder="Enter your full name"
                    />
                    {formErrors.fullName && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.fullName}</p>
                    )}
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="contactNo" className="block text-sm font-medium text-gray-700 mb-1">
                      Contact Number
                    </label>
                    <input
                      type="tel"
                      id="contactNo"
                      name="contactNo"
                      value={formData.contactNo}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border ${formErrors.contactNo ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-red-500`}
                      placeholder="Enter your contact number"
                    />
                    {formErrors.contactNo && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.contactNo}</p>
                    )}
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="emailAddress"
                      name="emailAddress"
                      value={formData.emailAddress}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border ${formErrors.emailAddress ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-red-500`}
                      placeholder="Enter your email address"
                    />
                    {formErrors.emailAddress && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.emailAddress}</p>
                    )}
                  </div>
                  
                  <div className="flex gap-3 mt-6">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`flex-1 bg-red-600 hover:bg-red-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center ${isSubmitting ? 'opacity-70 cursor-not-allowed' : ''}`}
                    >
                      {isSubmitting ? 'Processing...' : 'Submit Enrollment'}
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowEnrollForm(false)}
                      className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors duration-300"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
              
              {/* Success Message */}
              {submitSuccess && (
                <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Check size={20} className="text-green-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-green-800 font-medium">Enrollment Successful!</h3>
                      <p className="text-green-700 text-sm mt-1">
                        Thank you for enrolling in our {course.title} course. We've sent a confirmation email to {formData.emailAddress}.
                      </p>
                      <button
                        onClick={resetForm}
                        className="mt-3 text-sm font-medium text-green-600 hover:text-green-800"
                      >
                        Back to Course Page
                      </button>
                    </div>
                  </div>
                </div>
              )}
              
              {/* Error Message */}
              {submitError && (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <X size={20} className="text-red-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-red-800 font-medium">Enrollment Failed</h3>
                      <p className="text-red-700 text-sm mt-1">
                        We couldn't process your enrollment. Please try again or contact support.
                      </p>
                      <button
                        onClick={() => setSubmitError(false)}
                        className="mt-3 text-sm font-medium text-red-600 hover:text-red-800"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              )}
              
              <div className="mt-6 border-t border-gray-200 pt-4">
                <h4 className="font-medium text-gray-800 mb-2">This course includes:</h4>
                <ul className="space-y-2">
                  <li className="flex items-center text-sm text-gray-600">
                    <span className="mr-2 text-green-500">✓</span>
                    <span>Professional instruction</span>
                  </li>
                  <li className="flex items-center text-sm text-gray-600">
                    <span className="mr-2 text-green-500">✓</span>
                    <span>Course materials and resources</span>
                  </li>
                  <li className="flex items-center text-sm text-gray-600">
                    <span className="mr-2 text-green-500">✓</span>
                    <span>Certificate upon completion</span>
                  </li>
                  <li className="flex items-center text-sm text-gray-600">
                    <span className="mr-2 text-green-500">✓</span>
                    <span>Job placement assistance</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-sm text-gray-500 text-end italic mt-4">
  * The rating and number of students enrolled are fabricated
</div>
    </div>
  );
};

export default SingleCourse;