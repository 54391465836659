import React, { useState } from 'react';
import { Loader2, CheckCircle, AlertCircle } from 'lucide-react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [status, setStatus] = useState('idle');
  const [focusedField, setFocusedField] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("API URL:", process.env.REACT_APP_API_URL);

    setStatus('loading');
    
    try {
      console.log("API URL:", process.env.REACT_APP_API_URL);

      await new Promise(resolve => setTimeout(resolve, 1000));
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formData);
      setStatus('success');
      setTimeout(() => setStatus('idle'), 2000);
    } catch (error) {
      setStatus('error');
      setTimeout(() => setStatus('idle'), 2000);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-6">
      <div className="max-w-lg w-full p-8 bg-white/10 backdrop-blur-lg shadow-2xl rounded-2xl transition-all duration-300 hover:shadow-red-500/30 border border-white/20">
        <h2 className="text-3xl font-extrabold text-white text-center mb-6">Contact Us</h2>

        <form onSubmit={handleSubmit} className="space-y-5">
          <InputField 
            label="Name" 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleChange}
            onFocus={() => setFocusedField('name')}
            onBlur={() => setFocusedField(null)}
            focused={focusedField === 'name'}
          />

          <InputField 
            label="Email" 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange}
            onFocus={() => setFocusedField('email')}
            onBlur={() => setFocusedField(null)}
            focused={focusedField === 'email'}
          />

          <InputField 
            label="Phone" 
            type="tel" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange}
            onFocus={() => setFocusedField('phone')}
            onBlur={() => setFocusedField(null)}
            focused={focusedField === 'phone'}
          />

          <TextAreaField 
            label="Message" 
            name="message" 
            value={formData.message} 
            onChange={handleChange}
            onFocus={() => setFocusedField('message')}
            onBlur={() => setFocusedField(null)}
            focused={focusedField === 'message'}
          />

          <button
            type="submit"
            className={`w-full bg-gradient-to-r from-red-600 to-red-700 hover:from-red-500 hover:to-red-600 text-white font-semibold py-3 px-6 
                       rounded-lg shadow-md transition-all duration-300 transform hover:scale-105 flex items-center justify-center
                       ${status === 'loading' ? 'opacity-75 cursor-not-allowed' : ''}`}
            disabled={status !== 'idle'}
          >
            {status === 'loading' && <Loader2 className="animate-spin mr-2" />}
            {status === 'success' && <CheckCircle className="mr-2" />}
            {status === 'error' && <AlertCircle className="mr-2" />}
            {status === 'idle' ? 'Send Message' : 
             status === 'loading' ? 'Sending...' :
             status === 'success' ? 'Sent!' : 'Error!'}
          </button>
        </form>
      </div>
    </div>
  );
};

const InputField = ({ label, type, name, value, onChange, onFocus, onBlur, focused }) => (
  <div className={`relative transition-all duration-300 ${focused ? 'scale-105' : ''}`}>
    <label className="block text-sm font-medium mb-1 text-white">{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      className="w-full p-3 border border-white/30 bg-white/20 text-white rounded-lg focus:border-red-500 focus:ring-1 focus:ring-red-500
                 placeholder-white/70 transition-all duration-300 shadow-md backdrop-blur-md"
      required
    />
  </div>
);

const TextAreaField = ({ label, name, value, onChange, onFocus, onBlur, focused }) => (
  <div className={`relative transition-all duration-300 ${focused ? 'scale-105' : ''}`}>
    <label className="block text-sm font-medium mb-1 text-white">{label}</label>
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      className="w-full p-3 border border-white/30 bg-white/20 text-white rounded-lg focus:border-red-500 focus:ring-1 focus:ring-red-500
                 placeholder-white/70 transition-all duration-300 shadow-md backdrop-blur-md min-h-32"
      required
    />
  </div>
);

export default ContactForm;
