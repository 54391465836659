import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { Link } from 'react-router-dom';

const TrainingForm = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState("All");
  
  const data = [
    {
      id: 1,
      title: "HR Generalist",
      type: "HR & Talent Management",
      duration: "8 weeks",
      rating: 4.5,
      description: "Comprehensive training for HR generalists covering all key HR functions."
    },
    {
      id: 2,
      title: "HR Analytics",
      type: "HR & Talent Management",
      duration: "6 weeks",
      rating: 4.7,
      description: "Learn to analyze HR data to make data-driven decisions."
    },
    {
      id: 3,
      title: "HR Policies Workshop",
      type: "HR & Talent Management",
      duration: "2 days",
      rating: 4.6,
      description: "Workshop focused on creating and implementing effective HR policies."
    },
    {
      id: 4,
      title: "HR Metrics",
      type: "HR & Talent Management",
      duration: "4 weeks",
      rating: 4.4,
      description: "Understand and apply key HR metrics to measure performance."
    },
    {
      id: 5,
      title: "Talent Acquisition",
      type: "HR & Talent Management",
      duration: "6 weeks",
      rating: 4.8,
      description: "Strategies and techniques for effective talent acquisition."
    },
    {
      id: 6,
      title: "Succession Planning Workshop",
      type: "HR & Talent Management",
      duration: "2 days",
      rating: 4.5,
      description: "Learn to develop and implement succession planning strategies."
    },
    {
      id: 7,
      title: "Organizational Development",
      type: "HR & Talent Management",
      duration: "8 weeks",
      rating: 4.7,
      description: "Training on organizational development strategies and practices."
    },
    {
      id: 8,
      title: "Payroll Workshop",
      type: "Payroll & Compliance",
      duration: "3 days",
      rating: 4.6,
      description: "Hands-on workshop for managing payroll processes."
    },
    {
      id: 9,
      title: "Payslip Generation Workshop",
      type: "Payroll & Compliance",
      duration: "2 days",
      rating: 4.5,
      description: "Learn to generate and manage payslips effectively."
    },
    {
      id: 10,
      title: "Power BI for HR",
      type: "Payroll & Compliance",
      duration: "4 weeks",
      rating: 4.8,
      description: "Use Power BI to visualize and analyze HR data."
    },
    {
      id: 11,
      title: "Statutory Compliances",
      type: "Payroll & Compliance",
      duration: "6 weeks",
      rating: 4.7,
      description: "Understand and implement statutory compliance requirements."
    },
    {
      id: 12,
      title: "Labour Laws",
      type: "Payroll & Compliance",
      duration: "4 weeks",
      rating: 4.6,
      description: "Comprehensive training on labor laws and regulations."
    },
    {
      id: 13,
      title: "Leave Policies",
      type: "Payroll & Compliance",
      duration: "2 weeks",
      rating: 4.5,
      description: "Learn to design and implement effective leave policies."
    },
    {
      id: 14,
      title: "Interview Skills",
      type: "Interview & Career Development",
      duration: "4 weeks",
      rating: 4.7,
      description: "Develop and refine your interview skills for better performance."
    },
    {
      id: 15,
      title: "Mock Interview Workshops",
      type: "Interview & Career Development",
      duration: "2 days",
      rating: 4.6,
      description: "Practice and improve your interview techniques through mock interviews."
    },
    {
      id: 16,
      title: "Spoken English",
      type: "Interview & Career Development",
      duration: "8 weeks",
      rating: 4.8,
      description: "Improve your spoken English for professional communication."
    },
    {
      id: 17,
      title: "Business Communication",
      type: "Interview & Career Development",
      duration: "6 weeks",
      rating: 4.7,
      description: "Enhance your business communication skills for effective workplace interactions."
    },
    {
      id: 18,
      title: "Personality Development",
      type: "Interview & Career Development",
      duration: "8 weeks",
      rating: 4.6,
      description: "Develop your personality and soft skills for career growth."
    },
    {
      id: 19,
      title: "Advanced Excel",
      type: "Corporate & Skill-Based Training",
      duration: "6 weeks",
      rating: 4.8,
      description: "Master advanced Excel techniques for data analysis and reporting."
    },
    {
      id: 20,
      title: "Skill Matrix Workshop",
      type: "Corporate & Skill-Based Training",
      duration: "2 days",
      rating: 4.5,
      description: "Learn to create and manage skill matrices for workforce planning."
    },
    {
      id: 21,
      title: "Corporate Training",
      type: "Corporate & Skill-Based Training",
      duration: "12 weeks",
      rating: 4.7,
      description: "Comprehensive corporate training program for skill development."
    },
    {
      id: 22,
      title: "Outbound Training",
      type: "Corporate & Skill-Based Training",
      duration: "3 days",
      rating: 4.6,
      description: "Outdoor training activities for team building and leadership development."
    },
    {
      id: 23,
      title: "Employee Retention Workshop",
      type: "Employee Engagement & Retention",
      duration: "2 days",
      rating: 4.7,
      description: "Strategies and techniques to improve employee retention."
    },
    {
      id: 24,
      title: "Internship",
      type: "Internship & Practical Exposure",
      duration: "12 weeks",
      rating: 4.8,
      description: "Gain practical exposure and hands-on experience through an internship."
    }
  ];

  // Get all unique course types for the filter
  const courseTypes = ["All", ...Array.from(new Set(data.map(course => course.type)))];
  
  // Filter courses based on search term and selected type
  const filteredData = data.filter(course => 
    (selectedType === "All" || course.type === selectedType) && 
    (course.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
     course.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Group courses by their type
  const groupedCourses = filteredData.reduce((acc, course) => {
    if (!acc[course.type]) {
      acc[course.type] = [];
    }
    acc[course.type].push(course);
    return acc;
  }, {});

  // Function to render star ratings
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const stars = [];
    
    for (let i = 0; i < fullStars; i++) {
      stars.push(<span key={`full-${i}`} className="text-yellow-400">★</span>);
    }
    
    if (halfStar) {
      stars.push(<span key="half" className="text-yellow-400">★</span>);
    }
    
    const emptyStars = 5 - stars.length;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<span key={`empty-${i}`} className="text-gray-300">★</span>);
    }
    
    return stars;
  };

  // Function to determine the duration badge color
  const getDurationColor = (duration) => {
    if (duration.includes("days")) return "bg-green-100 text-green-800";
    if (duration.includes("weeks") && parseInt(duration) <= 4) return "bg-blue-100 text-blue-800";
    if (duration.includes("weeks") && parseInt(duration) <= 8) return "bg-purple-100 text-purple-800";
    return "bg-red-100 text-red-800";
  };

  return (
    <div className="bg-gray-50 min-h-screen p-4 md:p-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-gradient-to-r from-red-600 to-red-800 rounded-xl p-8 mb-8 shadow-lg">
          <h1 className="text-3xl md:text-4xl font-bold text-white mb-4 text-center">Professional Training Courses</h1>
          <p className="text-white text-center mb-6 opacity-90">Advance your career with our industry-recognized training programs</p>
          
          {/* Search and filter bar */}
          <div className="flex flex-col md:flex-row gap-4 mx-auto max-w-4xl bg-white p-4 rounded-lg shadow-md">
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search size={20} className="text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Search courses..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <select
              className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600 bg-white"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              {courseTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>

        {Object.keys(groupedCourses).length > 0 ? (
          Object.entries(groupedCourses).map(([type, courses]) => (
            <div key={type} className="mb-12">
              <div className="flex items-center mb-6">
                <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">{type}</h2>
                <div className="ml-4 h-px bg-red-600 flex-grow opacity-50"></div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {courses.map((course) => (
                  <div 
                    key={course.id} 
                    className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 border border-gray-100 flex flex-col"
                  >
                    <div className="p-6 flex-grow">
                      <div className="flex justify-between items-start mb-3">
                        <h3 className="text-xl font-bold text-gray-800 mb-2">{course.title}</h3>
                        <span className={`text-xs font-medium px-2.5 py-0.5 rounded-full ${getDurationColor(course.duration)}`}>
                          {course.duration}
                        </span>
                      </div>
                      <div className="flex mb-3">
                        {renderStars(course.rating)}
                        <span className="ml-2 text-sm text-gray-600">({course.rating})</span>
                      </div>
                      <p className="text-gray-600 text-sm">{course.description}</p>
                    </div>
                    <div className="mt-auto">
                      <Link to={`/course/${course.id}`}>
                      <button className="w-full bg-red-600 hover:bg-red-700 text-white font-medium py-3 transition-colors duration-300 flex items-center justify-center">
                        Enroll Now
                        <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                        </svg>
                      </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-12">
            <h3 className="text-xl text-gray-700 mb-2">No courses match your search</h3>
            <p className="text-gray-500">Try adjusting your search terms or filters</p>
          </div>
        )}
      </div>
      <div className="text-sm text-gray-500 text-end italic mt-4">
  * The rating and number of students enrolled are superfluous
</div>
    </div>
  );
};

export default TrainingForm;