import React, { useState } from 'react';
import axios from 'axios';
import styles from './PriceQuoteForm.module.css'; // CSS module for styling
const OdForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    service: '',
    details: '',
  });

  const services = [
    "Performance Management",
    "Employees Survey",
    "Employee Engagement",
    "Training & Development",
    "New Joiners Induction",
    "Creating Job Descriptions",
    "Payroll Management",
    "Policy Formulations",
    "Competency Assessments",
    "Organization Charts",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/od`, formData);
      if (response.status === 201) {
        alert('Form submitted successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          service: '',
          details: '',
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Get a Price Quote</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="firstName" className={styles.label}>First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="lastName" className={styles.label}>Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email" className={styles.label}>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="phone" className={styles.label}>Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="service" className={styles.label}>Select a Service:</label>
          <select
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            className={styles.select}
            required
          >
            <option value="">--Select a Service--</option>
            {services.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="details" className={styles.label}>Give us more details</label>
          <textarea
            id="details"
            name="details"
            rows="4"
            value={formData.details}
            onChange={handleChange}
            className={styles.textarea}
            required
          ></textarea>
        </div>
        <button type="submit" className={styles.submitButton}>Submit</button>
      </form>

      <div className={styles.contactInfo}>
        <p className={styles.email}>info@kaarlo.in</p>
        {/* <div className={styles.socialLinks}>
          <a href="#" aria-label="YouTube" className={styles.socialLink}>YouTube</a>
          <a href="#" aria-label="LinkedIn" className={styles.socialLink}>LinkedIn</a>
          <a href="#" aria-label="Facebook" className={styles.socialLink}>Facebook</a>
          <a href="#" aria-label="Instagram" className={styles.socialLink}>Instagram</a>
          <a href="#" aria-label="Twitter" className={styles.socialLink}>Twitter</a>
        </div> */}
      </div>

      <footer className={styles.footer}>
        <p>©2023 by Kaarlo Training & HR Solutions Pvt. Ltd.</p>
      </footer>
    </div>
  );
};

export default OdForm;