import React from 'react';
import ContactForm from '../forms/contactForm';
import ContactSection from '../common/contactUs';

const Contact = ()=>{
  return (
    <div className="min-h-screen flex flex-col">
      
      <ContactSection/>      
      <ContactForm/>
      </div>
  )
}

export default Contact;