const blogsJson=[
  {
    "id": 1,
    "genre": "Career Development",
    "author": "Charles P",
    "title": "How to Choose the Right Company to Work For?",
    "date": "2023-04-21",
    "updated": "2023-05-12",
    "readTime": "3 min read",
    "likes": 0,
    "comments": 0,
    "imageUrl": "/blogs/1.webp",
    "tags": ["Career Advice", "Job Search", "Company Research"],
    "excerpt": "Before joining a company, research its culture, values, and industry to ensure the right fit. Learn key steps to making an informed career decision.",
    "content": "### Why Researching a Company Matters\n\nDuring interviews, candidates often focus on impressing recruiters but forget to evaluate whether the company is a good fit for them. Just like choosing a life partner, selecting the right employer requires thorough research.\n\n### Key Takeaways:\n\n1) **Follow the Company on Social Media** – Observe their posts on LinkedIn, Twitter, Facebook, and Instagram to understand their values, work culture, and employee satisfaction.\n2) **Check Google Reviews & Ratings** – Read both positive and negative reviews to identify trends. A high percentage of negative reviews could indicate red flags.\n3) **Visit the Company Website** – Explore their business activities, infrastructure, and corporate culture through official images and content.\n4) **Seek Employee Insights** – Reach out to current or former employees for first-hand experiences about the work environment.\n5) **Understand the Industry** – Make sure the company operates in an industry that aligns with your interests and long-term career goals.\n\nBy following these steps, you can make a well-informed decision and avoid workplace dissatisfaction. Stay tuned for more career tips!"
  }
  ,
    {
      "id": 2,
      "genre": "Career",
      "author": "Charles P",
      "title": "Beat the Heat, Land Your Dream Job!",
      "date": "2024-04-09",
      "readTime": "2 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/2.webp",
      "tags": ["Career", "Job Market", "Virtual Job Fair"],
      "excerpt": "Discover how the Mega Virtual Job Fair can help you land your dream job while skipping the scorching interview queues.",
      "content": "Hey there, job seeker! Feeling the Indian summer sun beat down? Same. But what if we told you there's a way to land your dream job AND skip the scorching interview queues? Introducing the Mega Virtual Job Fair, happening on April 13th! The Indian job market offers promising opportunities, especially for candidates with in-demand skills and a willingness to adapt to the evolving work landscape. Here's the inside scoop on what recruiters are looking for: technical prowess, soft skills, remote work adaptability, and a growth mindset. Wondering what specific skills are in hot demand? Digital marketing, data analysis, AI, and cybersecurity top the list. With our virtual job fair, you can connect with top companies from the comfort of your home. Ready to take control of your career? Register for the Mega Virtual Job Fair at https://zrec.in/QBaG2?source=CareerSite and unlock a world of exciting possibilities!"
    },
    {
      "id": 3,
      "genre": "Career Development",
      "author": "Charles P",
      "title": "The Power of Spoken English in Your Professional Journey",
      "date": "2024-01-10",
      "readTime": "4 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/3.webp",
      "tags": ["Spoken English", "Career Growth", "Professional Development"],
      "excerpt": "Discover how mastering spoken English can shape your career success and open doors to global opportunities.",
      "content": "In the exciting world of careers, where dreams guide us and ambitions light up our path, there's something special we might not think about too much – speaking English well. Imagine your career journey like a big adventure story, and at its heart is the way we talk to each other. Whether engaging in team meetings, negotiations, or presentations, the impact of eloquent and well-articulated speech cannot be overstated. A professional's command over spoken English serves as a gateway to express competence, credibility, and confidence, setting the stage for success and upward mobility in their career. Mastering spoken English helps professionals stand out in a global job market, adapt to industry needs, and confidently navigate interviews and workplace communication. At Kaarlo Training & HR Solutions Pvt. Ltd., we have over 5 years of expertise in training students, employees, and job seekers in spoken English, empowering them to achieve their career goals. Click here to apply for a course or reach out to us via call or WhatsApp at 7358999874."
    }
    ,
    {
      "id": 4,
      "genre": "Career Development",
      "author": "Charles P",
      "title": "Beyond Graduation: The Secret Weapon Every Fresher Needs for Job Hunt",
      "date": "2023-11-29",
      "readTime": "5 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/4.webp",
      "tags": ["Internships", "Career Growth", "Freshers"],
      "excerpt": "Internships are the secret weapon every fresher needs to land their dream job. Learn how to make the most of them!",
      "content": "In the last blog, we discussed how college freshers could secure a job while still studying. Now, let's explore the secret weapon that can help any fresher land their dream job—internships! Internships provide hands-on experience, bridging the gap between academic learning and real-world application. Whether you aim to build skills, expand your network, or secure a job offer, choosing the right internship is crucial. Opt for startups for better learning exposure, focus on experience over stipends, and dedicate more time to your role. Voluntary and frequent internships enhance employability, making you a top choice for recruiters. Looking for an HR internship or job opportunities? Apply now at www.kaarlo.in and kick-start your career journey with Kaarlo."
    }
    ,
    {
      "id": 5,
      "genre": "Career Development",
      "author": "Charles P",
      "title": "Jobs for Freshers – How to Get Job Offers While Still at College?",
      "date": "2023-05-08",
      "readTime": "5 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/5.webp",
      "tags": ["Freshers", "Job Hunting", "College Students"],
      "excerpt": "College is not just for learning—it’s for preparing to become an employee. Discover how freshers can develop essential skills and secure job offers before graduation.",
      "content": "Many college students focus solely on academics, missing the bigger picture—preparing for employment. Employers look for both good scores and practical skills. To increase job prospects, freshers should focus on four key areas: \n\n1) **Speaking in English** – Practice with peers, seek feedback, and improve gradually. \n2) **Asking Doubts** – Engage with professors, seniors, and industry experts to gain deeper clarity. \n3) **Working as a Team** – Take leadership roles, participate in group projects, and enhance teamwork skills. \n4) **Networking** – Connect beyond your circle, leverage referrals, and build a strong professional network. \n\nDeveloping these skills throughout college ensures a smoother transition to the job market, giving freshers a head start in securing job offers before graduation. Share this with someone who needs it and start preparing today!"
    }
    ,
    {
      "id": 6,
      "genre": "Career Development",
      "author": "Charles P",
      "title": "What Are Your Strengths and Weaknesses? – What Do Interviewers Like to Hear?",
      "date": "2023-05-04",
      "readTime": "4 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/6.webp",
      "tags": ["Interviews", "Job Preparation", "Soft Skills"],
      "excerpt": "Answering the 'Strengths and Weaknesses' question in an interview can be tricky. Learn how to craft responses that highlight your strengths and present weaknesses positively.",
      "content": "Interviewers frequently ask candidates about their strengths and weaknesses. Many candidates give generic responses like 'good communication' or 'leadership skills.' However, strengths should be unique and backed by examples. \n\n**Key points:** \n\n1) **Strengths Should Be Specific** – Instead of just saying 'I have good communication skills,' provide an example like 'I have strong convincing skills and once persuaded six friends to watch my movie choice after a debate.' \n2) **Match Strengths to Job Requirements** – Read job postings carefully and highlight relevant skills. Technical skills like Python, Excel, or Java should also be mentioned with examples of expertise. \n3) **Handling Weaknesses** – Avoid saying you have no weaknesses or revealing major flaws. Instead, frame past weaknesses as challenges you’ve overcome. Example: 'I struggled with time management but improved over the last two years using structured planning techniques.' \n\nBy following these strategies, candidates can confidently answer this common interview question and leave a strong impression. Share this with someone preparing for an interview!"
    }
    ,
    {
      "id": 7,
      "genre": "Career Development",
      "author": "Charles P",
      "title": "Don't Introduce Yourself Like This in an Interview",
      "date": "2023-04-27",
      "readTime": "3 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/7.webp",
      "tags": ["Interviews", "Job Preparation", "Soft Skills"],
      "excerpt": "Avoid memorized scripts and unnecessary formalities in self-introductions during interviews. Learn how to introduce yourself naturally and effectively.",
      "content": "Many freshers begin their self-introduction with scripted phrases like 'First of all, I would like to thank you for this wonderful opportunity.' However, such statements are unnecessary and make the introduction sound rehearsed. \n\n**Key Points:** \n\n1) **Avoid Unnecessary Formalities** – The interviewer already knows your name from your resume, so there's no need to repeat it unless the situation demands it. \n2) **Go with the Flow** – Adapt your introduction to the context of the conversation rather than following a rigid script. \n3) **Focus on Relevant Details** – Your introduction should highlight key aspects such as: \n   - Your degree and college. \n   - Work experience and technical expertise. \n   - Achievements and strengths (only if you can provide examples). \n4) **Don't Recite, Be Natural** – Instead of memorizing pre-designed templates from YouTube, write your introduction in your own words and practice delivering it naturally. \n5) **Keep It Short & Sweet** – If mentioning your family, keep it brief without listing names. \n\nA well-prepared but natural self-introduction sets the right tone for your interview. Practice confidently and adapt based on the situation!"
    }
    ,
    {
      "id": 8,
      "genre": "Career Development",
      "author": "Charles P",
      "title": "Don't Just Look at the Salary & Decide!",
      "date": "2023-04-24",
      "updated": "2023-05-12",
      "readTime": "3 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/8.webp",
      "tags": ["Job Search", "Career Advice", "Job Applications"],
      "excerpt": "Salary is an important factor in job selection, but it shouldn't be the only one. Learn why reading job descriptions and specifications is crucial before applying.",
      "content": "Many job seekers prioritize salary when applying for jobs, often overlooking crucial details like job descriptions and requirements. This can lead to mismatched expectations, job dissatisfaction, and unnecessary interview processes. \n\n### Key Takeaways: \n\n1) **Salary Isn't Everything** – A high salary may not always mean job satisfaction if the role doesn't match your skills and interests. \n2) **Always Read the Job Description** – This section outlines the roles and responsibilities of the job, ensuring you understand what will be expected of you. \n3) **Pay Attention to Job Specifications** – Employers list required skills, experience, and qualifications. If you match most of them, your chances of getting hired increase. \n4) **Check for Perks & Benefits** – Things like medical insurance, paid vacations, and incentives reflect a company’s culture and employee value. \n5) **Save Time by Applying Smartly** – Reading these sections thoroughly takes only a few minutes but can prevent mismatches and wasted effort. \n\nTaking the time to go through a job posting in detail will help you make informed decisions and find roles that truly align with your career goals. Happy job searching!"
    }
    ,
    {
      "id": 9,
      "genre": "Career Development",
      "author": "Charles P",
      "title": "This is Why You Are Afraid of Interviews",
      "date": "2023-04-20",
      "updated": "2023-04-21",
      "readTime": "2 min read",
      "likes": 0,
      "comments": 0,
      "imageUrl": "/blogs/9.webp",
      "tags": ["Interview Tips", "Career Advice", "Job Search"],
      "excerpt": "Many candidates skip interviews due to fear and misconceptions. Learn how changing your mindset can help you confidently approach job interviews.",
      "content": "Recruiters often struggle with candidates not showing up for scheduled interviews, despite high unemployment rates. Over 30% of candidates either avoid calls or cancel last minute. \n\n### Key Takeaways: \n\n1) **Interviews Are Not Tests** – Many candidates fear interviews because they see them as assessments rather than discussions. \n2) **Think of Interviews as Meetings** – It’s a two-way conversation where both the candidate and the employer assess each other. \n3) **Interviews Are Like Dating** – You talk, see if there’s a mutual fit, and decide whether to move forward. \n4) **Adopt the Right Mindset** – If you see interviews as opportunities rather than challenges, you’ll be more confident and prepared. \n5) **You Have a Choice** – Just as companies evaluate candidates, candidates can also decide if the job is right for them. \n\nApproach interviews with confidence, knowing that it’s simply a discussion to find the right fit. Stay tuned for more tips on interview preparation!"
    }
    
]
export default blogsJson;