import React, { useState } from 'react';
import { UserCircle, Mail, Phone, Clock, Building } from 'lucide-react';
import axios from 'axios';
const TrainingForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    program: '',
    callTime: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/registrations`, formData,{
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
       
        alert('Form submitted successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          program: '',
          callTime: ''
        });
      
    } catch (error) {
      if (error.response) {
        console.error('Error Response:', error.response.data);
      } else if (error.request) {
        console.error('No Response Received:', error.request);
      } else {
        console.error('Error Setting Up Request:', error.message);
      }
      console.error('Error:', error);
      alert('There was an error submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8 flex items-center justify-center">
      <div className="w-full max-w-xl mx-auto bg-white shadow-xl rounded-xl overflow-hidden">
        <div className="bg-gradient-to-r from-red-600 to-red-800 p-6">
          <h2 className="text-3xl font-bold text-white text-center">Training Registration</h2>
          <p className="text-red-100 text-center mt-2">Join our professional training program</p>
        </div>
        
        <div className="p-6 space-y-8">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-2">
                <label htmlFor="firstName" className="text-gray-700 font-medium flex items-center gap-2">
                  <UserCircle className="w-4 h-4 text-red-600" />
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-colors"
                  required
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="lastName" className="text-gray-700 font-medium flex items-center gap-2">
                  <UserCircle className="w-4 h-4 text-red-600" />
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-colors"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <label htmlFor="email" className="text-gray-700 font-medium flex items-center gap-2">
                <Mail className="w-4 h-4 text-red-600" />
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-colors"
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="phone" className="text-gray-700 font-medium flex items-center gap-2">
                <Phone className="w-4 h-4 text-red-600" />
                Phone
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-colors"
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="program" className="text-gray-700 font-medium flex items-center gap-2">
                <Building className="w-4 h-4 text-red-600" />
                Training Program
              </label>
              <select
                id="program"
                name="program"
                value={formData.program}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-colors"
                required
              >
                <option value="">Select a program</option>
                <option value="individual">Individual</option>
                <option value="corporate">Corporate</option>
                <option value="campus">Campus</option>
              </select>
            </div>

            <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
              <label className="text-gray-700 font-medium flex items-center gap-2">
                <Clock className="w-4 h-4 text-red-600" />
                What time should we call you?
              </label>
              <div className="grid grid-cols-2 gap-4">
                {['Morning', 'Afternoon', 'Evening', 'Anytime'].map((time) => (
                  <div key={time} className="flex items-center space-x-2 bg-white p-3 rounded-lg border border-gray-200 hover:border-red-500 transition-colors">
                    <input
                      type="radio"
                      name="callTime"
                      value={time.toLowerCase()}
                      id={time.toLowerCase()}
                      checked={formData.callTime === time.toLowerCase()}
                      onChange={handleChange}
                      className="text-red-600 focus:ring-red-500 h-4 w-4"
                    />
                    <label htmlFor={time.toLowerCase()} className="cursor-pointer">{time}</label>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
        
        <div className="bg-gray-50 p-6">
          <button 
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="w-full bg-gradient-to-r from-red-600 to-red-800 hover:from-red-700 hover:to-red-900 text-white py-4 px-6 rounded-lg font-semibold transition-all transform hover:scale-[1.02] disabled:opacity-70 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Submitting...' : 'Submit Registration'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrainingForm;