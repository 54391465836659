import React from 'react';
import { motion } from 'framer-motion';
import { MapPin, Mail, Phone } from 'lucide-react';

const ContactSection = () => {
  return (
    <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center p-4 lg:p-8">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="bg-white rounded-lg shadow-xl p-6 md:p-8 lg:p-12 w-full max-w-6xl mx-auto"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          {/* Left Section */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="flex flex-col justify-center"
          >
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-red-600 mb-8">
              CONTACT US
            </h1>
            <div className="prose lg:prose-lg">
              <p className="text-xl md:text-2xl text-gray-700 leading-relaxed">
                We're here to help you with all your training and HR needs. Reach out to us through any of the channels below.
              </p>
            </div>
          </motion.div>

          {/* Right Section */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="space-y-8"
          >
            <div className="space-y-4">
              <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">
                Kaarlo Training & HR Solutions Pvt. Ltd.
              </h2>
              <motion.div 
                whileHover={{ scale: 1.02 }}
                className="flex items-start space-x-4 text-gray-600 bg-gray-50 p-4 rounded-lg"
              >
                <MapPin className="w-6 h-6 mt-1 flex-shrink-0 text-red-500" />
                <p className="text-lg md:text-xl leading-relaxed">
                  2C, Ample Arudhra Towers,<br />
                  Rajaram Salai, KK Nagar,<br />
                  Trichy 620021, Tamil Nadu
                </p>
              </motion.div>
            </div>

            <div className="space-y-4">
              <motion.div 
                whileHover={{ scale: 1.02 }}
                className="flex items-center space-x-4 text-gray-600 bg-gray-50 p-4 rounded-lg"
              >
                <Mail className="w-6 h-6 text-red-500" />
                <a href="mailto:info@kaarlo.in" className="text-lg md:text-xl hover:text-red-600 transition-colors">
                  info@kaarlo.in
                </a>
              </motion.div>

              <motion.div 
                whileHover={{ scale: 1.02 }}
                className="flex items-center space-x-4 text-gray-600 bg-gray-50 p-4 rounded-lg"
              >
                <Phone className="w-6 h-6 text-red-500" />
                <div className="space-y-2">
                  <a href="tel:7358999874" className="block text-lg md:text-xl hover:text-red-600 transition-colors">
                    +91 7358999874
                  </a>
                  <a href="tel:04313500517" className="block text-lg md:text-xl hover:text-red-600 transition-colors">
                    0431 3500517
                  </a>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactSection;