import React from 'react';
import components from './homeElements'
import VideoSlider from '../components/videoslider';
const Home = () => {
  return (
    <div className="min-h-screen flex flex-col">
        <components.HeroSection/>
        <components.StatsAndReasons/>
        <components.FeaturesSection/>
        <components.RecruitmentSections/>
        
        <components.WhatWeDo/>
        <VideoSlider/>
        <components.StoriesAndLife/> 
            

          </div>
  );
};

export default Home;