import { Phone, Mail, MapPin } from 'lucide-react'; // Assuming you are using react-feather for icons

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-12">
          <div>
            <h3 className="font-bold text-xl mb-6">Quick Links</h3>
            <ul className="space-y-3">
              {['Home', 'About', 'Blogs', 'Contact','Careers'].map((link) => (
                <li key={link}>
                  <a href={`/${link.toLowerCase()}`} 
                     className="hover:text-blue-400 transition-colors duration-200">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-xl mb-6">Our Services</h3>
            <ul className="space-y-3">
              {['Training Development', 'Job Openings', 'Internship', 'Inquire'].map((service) => (
                <li key={service}>
                  <a href={`/${service.charAt(0).toLowerCase()+ service.slice(1).replace(' ', '')}`} 
                     className="hover:text-blue-400 transition-colors duration-200">
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-xl mb-6">Recruitment & Training Solutions</h3>
            <ul className="space-y-3">
              
                <li key='Job Seekers'>
                  <a href={`https://kaarlo.zohorecruit.in/candidateportal`} 
                     className="hover:text-blue-400 transition-colors duration-200">
                    Job Seekers
                  </a>
                </li>
              
                <li key='Employers'>
                  <a href={`https://kaarlo.zohorecruit.in/clientportal`} 
                     className="hover:text-blue-400 transition-colors duration-200">
                    Employers
                  </a>
                </li>
              
                <li key='Vendors'>
                  <a href={`https://kaarlo.zohorecruit.in/vendorportal`} 
                     className="hover:text-blue-400 transition-colors duration-200">
                    Vendors
                  </a>
                </li>
                <li key='Training'>
                  <a href={`/trainingForm`} 
                     className="hover:text-blue-400 transition-colors duration-200">
                    Training Solutions
                  </a>
                </li>
                <li key='Courses'>
                  <a href={`/trainingDevelopment`} 
                     className="hover:text-blue-400 transition-colors duration-200">
                    Courses
                  </a>
                </li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-xl mb-6">Get in Touch</h3>
            <ul className="space-y-4">
              <li className="flex items-center space-x-3">
                <Phone className="w-5 h-5 text-blue-400" />
                <span>+917358999874</span>
              </li>
              <li className="flex items-center space-x-3">
                <Mail className="w-5 h-5 text-blue-400" />
                <span>info@kaarlo.com</span>
              </li>
              <li className="flex items-center space-x-3">
                <MapPin className="w-5 h-5 text-blue-400" />
                <span>Trichy 620021, Tamil Nadu</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-800 text-center">
          <p className="text-gray-400">&copy; 2023 Kaarlo Training & HR Solutions Pvt. Ltd. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
