import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProtectedPage = () => {
  const [collections, setCollections] = useState({
    contacts: [],
    courses: [],
    enrollments: [],
    internshipforms: [],
    odorms: [],
    trainingregistrations: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        setLoading(true);
        
        // Fetch all collections in parallel
        const [
          contactsRes,
          enrollmentsRes,
          internshipformsRes,
          odormsRes,
          trainingregistrationsRes
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/contacts`, {
            headers: { Authorization: `Bearer ${token}` },
          }),

          axios.get(`${process.env.REACT_APP_API_URL}/api/enrollments`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/internshipforms`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/odforms`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/trainingregistrations`, {
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);

        setCollections({
          contacts: contactsRes.data,
          enrollments: enrollmentsRes.data,
          internshipforms: internshipformsRes.data,
          odorms: odormsRes.data,
          trainingregistrations: trainingregistrationsRes.data
        });
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching collections:", err);
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
        
        // Only redirect to login if it's an authentication error
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          navigate("/login");
        }
      }
    };

    fetchData();
  }, [navigate]);

  const renderCollection = (collectionName, data) => {
    if (!data || data.length === 0) {
      return <p>No data in {collectionName}</p>;
    }

    return (
      <div className="collection-container">
        <h3>{collectionName}</h3>
        <div className="table-responsive">
          <table className="collection-table">
            <thead>
              <tr>
                {Object.keys(data[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {Object.keys(data[0]).map((key) => (
                    <td key={key}>
                      {typeof item[key] === 'object'
                        ? JSON.stringify(item[key])
                        : String(item[key])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="loading-container">
        <h2>Protected Page</h2>
        <p>Loading collections...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <h2>Protected Page</h2>
        <p className="error-message">{error}</p>
      </div>
    );
  }

  return (
    <div className="protected-page">
      <h2>MongoDB Collections</h2>
      
      <div className="collections-wrapper">
        {Object.entries(collections).map(([name, data]) => (
          <div key={name} className="collection-section">
            {renderCollection(name, data)}
          </div>
        ))}
      </div>
      
      <style jsx="true">{`
        .protected-page {
          padding: 20px;
        }
        
        .collections-wrapper {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        
        .collection-section {
          margin-bottom: 20px;
          padding: 15px;
          border: 1px solid #ddd;
          border-radius: 5px;
          background-color: #f9f9f9;
        }
        
        .collection-container h3 {
          text-transform: capitalize;
          margin-bottom: 10px;
          color: #333;
        }
        
        .table-responsive {
          overflow-x: auto;
        }
        
        .collection-table {
          width: 100%;
          border-collapse: collapse;
        }
        
        .collection-table th,
        .collection-table td {
          padding: 8px;
          border: 1px solid #ddd;
          text-align: left;
        }
        
        .collection-table th {
          background-color: #f2f2f2;
          font-weight: bold;
        }
        
        .collection-table tr:nth-child(even) {
          background-color: #f8f8f8;
        }
        
        .error-message {
          color: red;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default ProtectedPage;