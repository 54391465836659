import React, { useState } from "react";
import axios from "axios";
import { FaUser, FaEnvelope, FaPhone, FaUniversity } from "react-icons/fa"; // Icons for a professional look

const JoinInternship = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    collegeName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/internship`, formData);
      alert(response.data.message); // Success message
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        collegeName: "",
      });
    } catch (err) {
      alert("Error submitting form");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.heading}>Join Our Internship Program</h1>
        <p style={styles.subHeading}>Become an employee</p>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <FaUser style={styles.icon} />
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <FaUser style={styles.icon} />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <FaEnvelope style={styles.icon} />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <FaPhone style={styles.icon} />
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <FaUniversity style={styles.icon} />
            <input
              type="text"
              name="collegeName"
              placeholder="College Name"
              value={formData.collegeName}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <button type="submit" style={styles.button}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

// Styles for the component
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5", // Light grey background
    padding: "20px",
  },
  card: {
    backgroundColor: "#ffffff", // White background for the card
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    maxWidth: "500px",
    width: "100%",
  },
  heading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#d32f2f", // Red color for the heading
    textAlign: "center",
    marginBottom: "10px",
  },
  subHeading: {
    fontSize: "16px",
    color: "#616161", // Grey color for the subheading
    textAlign: "center",
    marginBottom: "30px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    border: "1px solid #e0e0e0", // Light grey border
    borderRadius: "5px",
    padding: "10px",
  },
  icon: {
    color: "#d32f2f", // Red color for icons
    marginRight: "10px",
  },
  input: {
    border: "none",
    outline: "none",
    width: "100%",
    fontSize: "16px",
  },
  button: {
    backgroundColor: "#d32f2f", // Red color for the button
    color: "#ffffff", // White text
    border: "none",
    borderRadius: "5px",
    padding: "12px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

export default JoinInternship;