// Filename - pages/about.js

import React from "react";
import { motion } from "framer-motion";
import "./AboutPage.css"; // Custom CSS for styling
import { Link } from "react-router-dom";

const AboutPage = () => {
  // Animation variants for smooth transitions
  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <div className="about-page">
      {/* Hero Section */}
      <motion.div 
        className="relative h-screen bg-gradient-to-br from-red-600 via-red-700 to-red-800 flex items-center justify-center overflow-hidden"
        initial={{ opacity: 0, scale: 1.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      >
        <div className="absolute inset-0 bg-black/40 z-0" />
        
        <motion.div 
          className="relative z-10 text-center px-4 max-w-4xl"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <motion.h1
            className="text-5xl md:text-7xl font-bold text-white mb-6 font-playfair"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            About Us
            <motion.div 
              className="h-1 bg-gradient-to-r from-red-400 to-red-300 w-32 mx-auto mt-4 rounded-full"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ delay: 0.8, duration: 1 }}
            />
          </motion.h1>

          <motion.p
            className="text-xl md:text-2xl text-red-100 max-w-2xl mx-auto mb-8 font-raleway"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            Empowering individuals and organizations with tailored solutions for
            recruitment, training, and organizational development.
          </motion.p>
          <Link to="/contact">
          <motion.button 
            className="bg-red-500 hover:bg-red-600 text-white px-8 py-4 rounded-full text-lg font-semibold transition-all duration-300 transform hover:scale-105 shadow-xl hover:shadow-red-500/30"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            
          >

            Discover More
          </motion.button>
          </Link>
        </motion.div>
/</motion.div>

      {/* Recruitment Solutions Section */}
      <motion.section
        className="section recruitment-solutions"
        variants={staggerContainer}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.h2 variants={fadeIn}>Recruitment Solutions</motion.h2>
        <motion.div className="card-container" variants={staggerContainer}>
          <motion.div className="card" variants={fadeIn}>
            <h3>Job Seekers</h3>
            <p>
              We empower job seekers by providing access to a wide range of job
              opportunities, career guidance, and interview preparation.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Clients</h3>
            <p>
              We help businesses find top talent through customized recruitment
              solutions, ensuring a seamless hiring process.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Vendors</h3>
            <p>
              We collaborate with vendors to enhance our recruitment ecosystem,
              fostering trust and mutual growth.
            </p>
          </motion.div>
        </motion.div>
      </motion.section>

      {/* Corporate Training Solutions Section */}
      <motion.section
        className="section corporate-training"
        variants={staggerContainer}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.h2 variants={fadeIn}>Corporate Training Solutions</motion.h2>
        <motion.div className="card-container" variants={staggerContainer}>
          <motion.div className="card" variants={fadeIn}>
            <h3>Soft Skills Training</h3>
            <p>
              Enhance communication, leadership, and teamwork skills to boost
              workplace productivity.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Technical Training</h3>
            <p>
              Stay ahead with industry-specific technical training on the latest
              tools and technologies.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Certifications</h3>
            <p>
              Validate your expertise with certifications recognized by industry
              leaders.
            </p>
          </motion.div>
        </motion.div>
      </motion.section>

      {/* Campus Training Solutions Section */}
      <motion.section
        className="section campus-training"
        variants={staggerContainer}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.h2 variants={fadeIn}>Campus Training Solutions</motion.h2>
        <motion.div className="card-container" variants={staggerContainer}>
          <motion.div className="card" variants={fadeIn}>
            <h3>Soft Skills Training</h3>
            <p>
              Equip students with essential life skills for a smooth transition
              into professional environments.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Technical Training</h3>
            <p>
              Hands-on training to make students industry-ready with the latest
              technologies.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Internships</h3>
            <p>
              Real-world experience to build confidence and professional
              networks.
            </p>
          </motion.div>
        </motion.div>
      </motion.section>

      {/* Organizational Development Section */}
      <motion.section
        className="section org-development"
        variants={staggerContainer}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.h2 variants={fadeIn}>Organizational Development Solutions</motion.h2>
        <motion.div className="card-container" variants={staggerContainer}>
          <motion.div className="card" variants={fadeIn}>
            <h3>Payroll Management</h3>
            <p>
              Streamline payroll operations with accurate and timely processing.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Performance Management</h3>
            <p>
              Align employee goals with organizational objectives for continuous
              improvement.
            </p>
          </motion.div>
          <motion.div className="card" variants={fadeIn}>
            <h3>Employee Engagement</h3>
            <p>
              Foster a positive work culture with team-building and wellness
              initiatives.
            </p>
          </motion.div>
        </motion.div>
      </motion.section>
    </div>
  );
};

export default AboutPage;