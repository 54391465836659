import React from 'react';

const JobOpenings = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="max-w-2xl w-full bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="bg-gradient-to-r from-red-600 to-red-800 text-white p-8 text-center">
          <h1 className="text-4xl font-bold mb-4">Join Our Team! 🚀</h1>
        </div>
        
        <div className="p-8 space-y-6">
          <p className="text-gray-700 leading-relaxed text-lg">
            We're always on the lookout for talented individuals to join our growing team. If you're passionate, driven, and ready to make an impact, explore our current job openings and take the next step in your career with us.
          </p>
          
          <div className="bg-gray-50 border border-gray-200 rounded-xl p-6">
            <h2 className="text-2xl font-semibold text-red-700 mb-4">Why Join Us?</h2>
            <ul className="space-y-3 text-gray-600">
              <li className="flex items-center">
                <span className="mr-3 text-red-600">●</span>
                Dynamic work environment
              </li>
              <li className="flex items-center">
                <span className="mr-3 text-red-600">●</span>
                Growth opportunities
              </li>
              <li className="flex items-center">
                <span className="mr-3 text-red-600">●</span>
                Culture of innovation and collaboration
              </li>
            </ul>
          </div>
          
          <div className="text-center">
            <button 
              onClick={() => window.open('https://kaarlo.zohorecruit.in/jobs/Careers', '_blank')}
              className="bg-red-600 hover:bg-red-700 text-white px-8 py-3 text-lg rounded-full shadow-lg transition-transform transform hover:scale-105"
            >
              Browse Job Listings
            </button>
          </div>
        </div>
        
        <div className="bg-gray-100 p-4 text-center text-gray-500">
          © 2025 Kaarlo Staffing. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default JobOpenings;