import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Play, Pause, Maximize, Minimize } from 'lucide-react';

const VideoSlider = () => {
  const [videos] = useState([
    { id: 1, youtubeId: 'dixfSsCNsw4', title: 'Discover Kaarlo Training & HR Solutions | Empowering Careers & Organizations' },
    { id: 2, youtubeId: 'g_6O0exUceU', title: 'Naan Semma Azhagu' },
    { id: 3, youtubeId: 'vEt8O_lRHV4', title: 'Why do I keep wasting my time ? | Time Management Tips' },
    { id: 4, youtubeId: 'CSAcFywi5cY', title: 'Biggest Personality Secrets nobody told you in Tamil' },
    { id: 5, youtubeId: 'hpZc7StKDoQ', title: '3 Secrets for a Successful Career' },
    { id: 6, youtubeId: 'wsV7rV9HpOc', title: 'Getting Job Ready in the Competitive Job Market | Dhanalakshmi Srinivasan Womens college' },
    { id: 7, youtubeId: 't9C-ozUcetI', title: 'How will your Job be in the Post Corona World?' }
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [playerReady, setPlayerReady] = useState(false);
  const [isFullWidth, setIsFullWidth] = useState(true);
  const playerRef = useRef(null);
  const containerRef = useRef(null);

  // Initialize YouTube API
  useEffect(() => {
    // Only load the API once
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      
      // Create a promise to track when YT API is ready
      window.onYouTubeIframeAPIReady = () => {
        setPlayerReady(true);
      };
      
      document.head.appendChild(tag);
    } else {
      setPlayerReady(true);
    }

    return () => {
      if (playerRef.current) {
        try {
          playerRef.current.destroy();
        } catch (error) {
          console.error('Error destroying player:', error);
        }
      }
    };
  }, []);

  // Initialize player when API is ready and whenever currentIndex changes
  useEffect(() => {
    if (!playerReady) return;
    
    // Calculate container dimensions
    const updatePlayerDimensions = () => {
      if (containerRef.current && playerRef.current) {
        const container = containerRef.current;
        const viewportHeight = window.innerHeight;
        const maxHeight = viewportHeight * 0.7; // Maximum 70% of viewport height
        
        let width = container.clientWidth;
        // Calculate height based on 16:9 aspect ratio
        let height = width * (9/16);
        
        // If height exceeds max height, recalculate width to maintain aspect ratio
        if (height > maxHeight) {
          height = maxHeight;
          width = height * (16/9);
        }
        
        try {
          playerRef.current.setSize(width, height);
        } catch (error) {
          console.error('Error setting player size:', error);
        }
      }
    };

    const initializePlayer = () => {
      setIsLoading(true);
      
      // Clear previous player if it exists
      if (playerRef.current) {
        try {
          playerRef.current.destroy();
        } catch (error) {
          console.error('Error destroying previous player:', error);
        }
      }
      
      // Create a new player
      try {
        playerRef.current = new window.YT.Player('youtube-player', {
          videoId: videos[currentIndex].youtubeId,
          playerVars: {
            playsinline: 1,
            origin: window.location.origin,
            enablejsapi: 1,
            rel: 0,
            controls: 1, // Show native controls
            modestbranding: 1,
            autoplay: 0
          },
          events: {
            onReady: (event) => {
              updatePlayerDimensions();
              setIsLoading(false);
            },
            onStateChange: (event) => {
              if (event.data === window.YT.PlayerState.PLAYING) {
                setIsPlaying(true);
              } else if (event.data === window.YT.PlayerState.PAUSED || 
                         event.data === window.YT.PlayerState.ENDED) {
                setIsPlaying(false);
              }
            },
            onError: (event) => {
              console.error("YouTube player error:", event.data);
              setIsLoading(false);
            }
          }
        });
      } catch (error) {
        console.error('Player initialization failed:', error);
        setIsLoading(false);
      }
    };

    initializePlayer();
    
    // Handle resize events
    window.addEventListener('resize', updatePlayerDimensions);
    return () => window.removeEventListener('resize', updatePlayerDimensions);
  }, [playerReady, currentIndex, videos, isFullWidth]);

  const handlePlayPause = () => {
    if (!playerRef.current) return;
    
    try {
      if (isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
    } catch (error) {
      console.error('Error with play/pause:', error);
    }
  };

  const handleNavigation = (direction) => {
    const newIndex = (currentIndex + direction + videos.length) % videos.length;
    setCurrentIndex(newIndex);
    // The player will be reinitialized due to the dependency on currentIndex
  };

  const toggleFullWidth = () => {
    setIsFullWidth(!isFullWidth);
  };

  return (
    <div className="flex flex-col items-center justify-center py-8 bg-gradient-to-b from-gray-900 to-black text-white overflow-x-hidden">
      <div className={`w-full ${isFullWidth ? 'max-w-5xl' : 'max-w-3xl'} mx-auto px-4`}>
        {/* Video Title */}
        <div className="mb-4 text-center">
          <h2 className={`font-bold ${isFullWidth ? 'text-2xl' : 'text-xl'} text-white truncate`}>
            {videos[currentIndex].title}
          </h2>
        </div>
        
        {/* Video Container with Dynamic Sizing */}
        <div 
          ref={containerRef}
          className="relative bg-black rounded-xl shadow-2xl border border-gray-800 mx-auto overflow-hidden"
          style={{ 
            width: '100%',
            maxHeight: isFullWidth ? '70vh' : '50vh'
          }}
        >
          {/* YouTube Player Container */}
          <div id="youtube-player" className="mx-auto"></div>

          {/* Loading Overlay */}
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900/80 z-10">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-red-600 border-t-transparent" />
            </div>
          )}
          
          {/* Resize Toggle Button */}
          <button
            onClick={toggleFullWidth}
            className="absolute top-4 right-4 p-2 bg-black/60 hover:bg-red-600/80 text-white rounded-full z-20 transition-all duration-300 transform hover:scale-110"
            aria-label={isFullWidth ? "Reduce size" : "Expand size"}
          >
            {isFullWidth ? <Minimize size={20} /> : <Maximize size={20} />}
          </button>
        </div>

        {/* Controls */}
        <div className="mt-4 flex justify-center gap-6">
          <button
            onClick={() => handleNavigation(-1)}
            className="p-3 bg-red-600 text-white rounded-full hover:bg-red-700 transition-all duration-300 transform hover:scale-110 shadow-lg shadow-red-600/30 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            aria-label="Previous video"
          >
            <ChevronLeft size={isFullWidth ? 24 : 20} />
          </button>
          
          <button
            onClick={handlePlayPause}
            className="p-3 bg-red-600 text-white rounded-full hover:bg-red-700 transition-all duration-300 transform hover:scale-110 shadow-lg shadow-red-600/30 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            aria-label={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? <Pause size={isFullWidth ? 24 : 20} /> : <Play size={isFullWidth ? 24 : 20} />}
          </button>
          
          <button
            onClick={() => handleNavigation(1)}
            className="p-3 bg-red-600 text-white rounded-full hover:bg-red-700 transition-all duration-300 transform hover:scale-110 shadow-lg shadow-red-600/30 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            aria-label="Next video"
          >
            <ChevronRight size={isFullWidth ? 24 : 20} />
          </button>
        </div>

        {/* Video Selection */}
        <div className="mt-6 flex justify-center gap-3 flex-wrap">
          {videos.map((video, index) => (
            <button
              key={video.id}
              onClick={() => setCurrentIndex(index)}
              className={`transition-all duration-300 transform hover:scale-125 ${
                index === currentIndex 
                  ? `w-3 h-3 bg-red-600 ring-2 ring-red-400 ring-opacity-50` 
                  : `w-2 h-2 bg-gray-500 hover:bg-gray-300`
              } rounded-full`}
              aria-label={`Go to video ${index + 1}`}
            />
          ))}
        </div>
        
        {/* Progress Indicator */}
        <div className="mt-4 text-center text-gray-400 text-sm">
          {currentIndex + 1} of {videos.length}
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
