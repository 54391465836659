import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FaUser, FaCalendarAlt, FaFacebookF, FaTwitter, FaLinkedinIn, FaLink } from "react-icons/fa";
import blogJson from "./blogJson";
// Enhanced High-End Styles
const styles = {
  container: {
    maxWidth: "900px",
    margin: "40px auto",
    padding: "40px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
    fontFamily: "'Inter', system-ui, -apple-system, sans-serif",
    lineHeight: "1.8",
    color: "#333",
  },
  header: {
    textAlign: "center",
    marginBottom: "40px",
    position: "relative",
    paddingBottom: "25px",
    borderBottom: "1px solid #f0f0f0",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "800",
    color: "#1a2a3a",
    marginBottom: "25px",
    lineHeight: "1.2",
    letterSpacing: "-0.02em",
  },
  meta: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    fontSize: "0.95rem",
    color: "#666",
  },
  author: {
    margin: "0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  date: {
    margin: "0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    borderRadius: "12px",
    marginBottom: "40px",
    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.12)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    maxHeight: "500px",
  },
  content: {
    fontSize: "1.15rem",
    color: "#333",
    marginBottom: "40px",
    textAlign: "justify",
    lineHeight: "1.9",
    "& p": {
      marginBottom: "1.8em",
      fontSize: "1.15rem",
    },
    "& p:first-of-type::first-letter": {
      fontSize: "3.5em",
      lineHeight: "0.8",
      fontWeight: "600",
      float: "left",
      margin: "0.1em 0.1em 0.1em 0",
      color: "#1a2a3a",
    },
    "& h2": {
      fontSize: "1.8rem",
      fontWeight: "700",
      marginTop: "1.8em",
      marginBottom: "0.8em",
      color: "#1a2a3a",
    },
    "& h3": {
      fontSize: "1.5rem",
      fontWeight: "600",
      marginTop: "1.5em",
      marginBottom: "0.6em",
      color: "#1a2a3a",
    },
    "& blockquote": {
      borderLeft: "4px solid #3498db",
      paddingLeft: "20px",
      fontStyle: "italic",
      margin: "2em 0",
      color: "#555",
      backgroundColor: "#f9f9f9",
      padding: "20px",
      borderRadius: "0 8px 8px 0",
    },
    "& a": {
      color: "#3498db",
      textDecoration: "none",
      borderBottom: "1px solid rgba(52, 152, 219, 0.3)",
      transition: "border-bottom 0.2s ease, color 0.2s ease",
    },
    "& a:hover": {
      color: "#2980b9",
      borderBottom: "1px solid rgba(41, 128, 185, 0.8)",
    },
    "& ul, & ol": {
      marginBottom: "1.8em",
      paddingLeft: "1.5em",
    },
    "& li": {
      marginBottom: "0.8em",
    },
    "& img": {
      maxWidth: "100%",
      borderRadius: "8px",
      marginTop: "10px",
      marginBottom: "10px",
    },
    "& code": {
      backgroundColor: "#f5f7f9",
      padding: "3px 6px",
      borderRadius: "4px",
      fontFamily: "'Fira Code', monospace",
      fontSize: "0.9em",
    },
  },
  footer: {
    textAlign: "center",
    marginTop: "50px",
    paddingTop: "30px",
    borderTop: "1px solid #eaeaea",
  },
  tags: {
    fontSize: "0.95rem",
    color: "#555",
    fontStyle: "italic",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "10px",
  },
  tag: {
    backgroundColor: "#f0f5fa",
    padding: "6px 12px",
    borderRadius: "30px",
    fontSize: "0.85rem",
    color: "#3498db",
    fontWeight: "500",
    fontStyle: "normal",
    transition: "background-color 0.2s ease, transform 0.2s ease",
    cursor: "pointer",
  },
  relatedPosts: {
    marginTop: "40px",
    padding: "30px",
    backgroundColor: "#f9fafc",
    borderRadius: "12px",
  },
  relatedPostsTitle: {
    fontSize: "1.6rem",
    fontWeight: "700",
    marginBottom: "20px",
    color: "#1a2a3a",
    textAlign: "center",
  },
  relatedPostsList: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "20px",
  },
  relatedPostCard: {
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.06)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    backgroundColor: "#fff",
  },
  relatedPostImage: {
    width: "100%",
    height: "140px",
    objectFit: "cover",
  },
  relatedPostContent: {
    padding: "15px",
  },
  relatedPostTitle: {
    fontSize: "1.1rem",
    fontWeight: "600",
    margin: "0 0 8px 0",
    color: "#1a2a3a",
  },
  relatedPostDate: {
    fontSize: "0.8rem",
    color: "#777",
  },
  authorSection: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    marginTop: "40px",
    padding: "20px",
    backgroundColor: "#f7f9fc",
    borderRadius: "12px",
  },
  authorAvatar: {
    width: "64px",
    height: "64px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid #fff",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },
  authorInfo: {
    flex: 1,
  },
  authorName: {
    fontSize: "1.2rem",
    fontWeight: "700",
    margin: "0 0 5px 0",
    color: "#1a2a3a",
  },
  authorBio: {
    fontSize: "0.95rem",
    color: "#555",
    margin: 0,
  },
  socialShare: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    marginTop: "30px",
  },
  shareButton: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: "1.2rem",
    transition: "transform 0.3s ease, background-color 0.3s ease",
  },
  notFound: {
    textAlign: "center",
    fontSize: "1.8rem",
    color: "#ff6b6b",
    marginTop: "60px",
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  notFoundEmoji: {
    fontSize: "4rem",
    marginBottom: "20px",
  },
  notFoundMessage: {
    maxWidth: "600px",
    marginTop: "15px",
    fontSize: "1.1rem",
    color: "#666",
  },
  // Hover effects
  hoverEffects: {
    "& img:hover": {
      transform: "scale(1.02)",
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.15)",
    },
    "& .tag:hover": {
      backgroundColor: "#e1eff9",
      transform: "translateY(-2px)",
    },
    "& .relatedPostCard:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
    },
    "& .shareButton:hover": {
      transform: "translateY(-3px)",
    },
  },
  // Media queries for responsive design
  "@media (max-width: 768px)": {
    container: {
      padding: "25px",
      margin: "20px auto",
    },
    title: {
      fontSize: "2.2rem",
    },
    content: {
      fontSize: "1.05rem",
    },
    meta: {
      flexDirection: "column",
      gap: "10px",
    },
    authorSection: {
      flexDirection: "column",
      textAlign: "center",
    },
    relatedPostsList: {
      gridTemplateColumns: "1fr",
    },
  },
};

const BlogDetail = () => {
  
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  

  const { id } = useParams();
  const blog = blogJson.find((blog) => blog.id === parseInt(id));

  useEffect(() => {
    if (blog && blogJson.length > 0) {
      // Find related posts by matching tags
      const related = blogJson
        .filter(post => post.id !== blog.id && post.tags.some(tag => blog.tags.includes(tag)))
        .slice(0, 3);
      setRelatedPosts(related);
    }
  }, [blog, blogJson]);

  if (loading) {
    return (
      <div style={styles.container}>
        <div style={{ textAlign: 'center', padding: '50px 0' }}>
          <p>Loading post...</p>
        </div>
      </div>
    );
  }

  if (!blog) {
    return (
      <div style={styles.notFound}>
        <div style={styles.notFoundEmoji}>🧐</div>
        <div>Blog Post Not Found!</div>
        <p style={styles.notFoundMessage}>
          The post you're looking for doesn't exist or has been moved.
          <br />
          <Link to="/" style={{ color: '#3498db', textDecoration: 'none', marginTop: '20px', display: 'inline-block' }}>
            Return to Home
          </Link>
        </p>
      </div>
    );
  }

  // Format the date nicely
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div style={{...styles.container, ...styles.hoverEffects}}>
      <header style={styles.header}>
        <h1 style={styles.title}>{blog.title}</h1>
        <div style={styles.meta}>
          <p style={styles.author}>
            <FaUser style={{ marginRight: '5px', color: '#3498db' }} />
            <strong>By:</strong> {blog.author}
          </p>
          <p style={styles.date}>
            <FaCalendarAlt style={{ marginRight: '5px', color: '#3498db' }} />
            <strong>Published:</strong> {formatDate(blog.date)}
          </p>
        </div>
      </header>
      
      <img
        src={blog.imageUrl}
        alt={blog.title}
        style={styles.image}
      />
      
      <article style={styles.content} dangerouslySetInnerHTML={{ __html: blog.content }} />
      
      <div style={styles.authorSection}>
        <img 
          src={blog.authorAvatar || 'https://via.placeholder.com/64'} 
          alt={blog.author} 
          style={styles.authorAvatar}
        />
        <div style={styles.authorInfo}>
          <h3 style={styles.authorName}>{blog.author}</h3>
          <p style={styles.authorBio}>
            {blog.authorBio || `Writer and contributor with expertise in ${blog.tags.join(', ')}.`}
          </p>
        </div>
      </div>
      
      <div style={styles.socialShare}>
        <a href="https://www.facebook.com/kaarlotrichy/" style={{...styles.shareButton, backgroundColor: '#1877f2'}}>
          <FaFacebookF />
        </a>
        <a href="https://x.com/KaarloHr" style={{...styles.shareButton, backgroundColor: '#1da1f2'}}>
          <FaTwitter />
        </a>
        <a href="https://www.linkedin.com/company/kaarlo-trichy/?originalSubdomain=in" style={{...styles.shareButton, backgroundColor: '#0077b5'}}>
          <FaLinkedinIn />
        </a>
        <a href="#" style={{...styles.shareButton, backgroundColor: '#555'}}>
          <FaLink />
        </a>
      </div>
      
      <footer style={styles.footer}>
        <div style={styles.tags}>
          {blog.tags.map((tag, index) => (
            <span key={index} className="tag" style={styles.tag}>
              {tag}
            </span>
          ))}
        </div>
      </footer>
      
      {relatedPosts.length > 0 && (
        <div style={styles.relatedPosts}>
          <h2 style={styles.relatedPostsTitle}>You Might Also Enjoy</h2>
          <div style={styles.relatedPostsList}>
            {relatedPosts.map((post) => (
              <Link key={post.id} to={`/blog/${post.id}`} style={{ textDecoration: 'none' }}>
                <div className="relatedPostCard" style={styles.relatedPostCard}>
                  <img 
                    src={post.imageUrl} 
                    alt={post.title} 
                    style={styles.relatedPostImage} 
                  />
                  <div style={styles.relatedPostContent}>
                    <h3 style={styles.relatedPostTitle}>{post.title}</h3>
                    <p style={styles.relatedPostDate}>{formatDate(post.date)}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogDetail;