import React from 'react';

const CareersPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-red-600 to-red-700 text-white">
        <div className="max-w-5xl mx-auto px-6 py-16 text-center">
          <h1 className="text-5xl font-bold mb-4">COME WORK WITH US</h1>
          <div className="w-24 h-1 bg-white mx-auto my-6"></div>
          <h2 className="text-3xl font-light">Join the Kaarlo Team</h2>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-6 py-16">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          {/* Content Card */}
          <div className="p-8 md:p-12">
            <div className="text-center mb-12">
              <p className="text-gray-700 text-lg leading-relaxed mb-8">
                Please click the link below and submit your resume for a career at Kaarlo.
              </p>
              
              {/* Position Card */}
              <div className="group">
                <button 
                  onClick={() => window.open('https://kaarlo.zohorecruit.in/careers', '_blank')}
                  className="relative w-full bg-gray-50 border-2 border-red-100 rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:border-red-200"
                >
                  <div className="space-y-4">
                    <div className="text-2xl font-semibold text-red-600">
                      HR Recruiter
                    </div>
                    <div className="flex items-center justify-center space-x-2 text-gray-600">
                      <svg 
                        className="w-5 h-5" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth="2" 
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth="2" 
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <span>Trichy</span>
                    </div>
                  </div>
                  
                  <div className="absolute inset-x-0 bottom-0 h-1 bg-gradient-to-r from-red-500 to-red-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </button>
              </div>
            </div>

            {/* Additional Info */}
            <div className="text-center text-gray-600 mt-8">
              <p className="text-sm">
                Join us in shaping the future of technology
              </p>
            </div>
          </div>

          {/* Footer */}
          <div className="bg-gray-50 px-8 py-4 text-center text-gray-500 text-sm">
            <p>© 2025 Kaarlo. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersPage;