// src/App.js

import React from "react";
import { AuthProvider } from "./context/AuthContext";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";  // Make sure this path is correct
import Footer from "./pages/footer";
import Home from "./pages";
import About from "./pages/about";
import Blogs from "./pages/blogs/blogs";
import BlogDetail from "./pages/blogs/blogDetail";
import Contact from "./pages/contact";
import TrainingForm from "./forms/trainingForm";
import CourseTabs from "./pages/trainingDevelopment";
import JobOpenings from "./pages/jobopenings";
import CareersPage from "./pages/careers";
import Register from "./pages/register";
import Login from "./pages/login";
import ProtectedPage from "./pages/protected";
import JoinInternship from "./forms/internshipForm";
import OdForm from "./forms/odForm";
import SingleCourse from "./pages/coursePage";
function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/trainingDevelopment" element={<CourseTabs/>}/>
          <Route path="/course/:id" element={<SingleCourse />} />
          <Route path="/trainingForm" element={<TrainingForm/>}/>
          <Route path ="/jobOpenings" element={<JobOpenings/>} />
          <Route path ="/careers" element={<CareersPage/>} />
          <Route path="/blog/:id" element={<BlogDetail/>}/>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/protected" element={<ProtectedPage />} />
          <Route path="/internship" element={<JoinInternship/>}></Route>
          <Route path="/inquire" element={<OdForm/>}/>

        </Routes>
        <Footer/>
      </Router>
    </AuthProvider>
  );
}

export default App;